@import 'src/style/variable/variables.module';

#settings-map-screen .upgrade-title {
  width: 100%;
}

#settings-map-screen .upgrade-title td{
  font-size: 1.6rem;
}

#settings-map-screen .drag-container {
  min-width: 100%; /* Set your desired width */
  min-height: 100%; /* Set your desired height */
  background-color: #f0f3f8; /* Light grayish-blue background color */
  border: 2px dashed #a8b5d9; /* Light grayish-blue border */
  display: flex;
  justify-content: center;
  align-items: center;
}

#settings-map-screen  .upgrade-title .upgrade-titleText {
  display: flex;
}

#settings-map-screen  .check-circle-completed {
  color: $success;
}

#settings-map-screen  .check-circle-partial {
  color: $warning;
}

#settings-map-screen  .upgrade-title .upgrade-number {
  font-weight: bold;
  width: 80px;
}

#settings-map-screen  .upgrade-title .setting-title{
  font-weight: bold;
  width: 80px;
}

#settings-map-screen  .upgrade-title .generator {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
}

#settings-map-screen .MuiAccordionSummary-root  {
  height: 60px;
}

#settings-map-screen .setting-select{
  max-height: 40px;
}

#settings-map-screen .settings-map-container {
  min-height: 200px;
}

#settings-map-screen .condition-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

#settings-map-screen .condition-container > * {
  margin-bottom: 20px;
}

#settings-map-screen .details-content {
  max-width: 900px;
}

#settings-map-screen  .upgrade-title .generatorInner{
  overflow-wrap: inherit;
  margin-left: 5px;
}

#settings-map-screen .upgrade-bar {
  margin-bottom: 10px;
}

@media screen and (max-device-width: 775px) {
  #settings-map-screen  .upgrade-title .upgrade-number{
    display: none;
  }
}

#settings-map-screen  .upgrade-title .upgrade-titleButtons{
  display: flex;
  height: 100%;
}

#settings-map-screen  .settings-change {
  display: flex;
  align-items: center; /* Center vertically */
}

#settings-map-screen  .subtext {
  font-size: x-small;
  font-style: italic;
  margin-top: 2px;
  vertical-align: sub;
}

#settings-map-screen  .upgrade-title .upgrade-titleButtons .deleteButton{
  height: 20px;
  margin: auto;
  padding: 0;
}

#settings-map-screen  .upgrade-details {
  border: dashed 1px #c7c7c7;
  padding: 10px;
  margin-top: -0px
}
#settings-map-screen  .upgrade-details .inner{
  margin-top: -40px
}
#settings-map-screen .upgrade-details .title1{
  background: white;
  width: fit-content;
}
#settings-map-screen .upgrade-details .remove-branch-button {
  color: $quinary;
}

#settings-map-screen  .upgrade-details2 {
  border: dashed 1px #c7c7c7;
  padding: 10px;
  margin-top: 20px
}

#settings-map-screen  .condition-branch-container {
  margin-top: 35px;
  margin-bottom: 20px;
  max-width: 100%;
}

#settings-map-screen  .upgrade-details2 .inner{
  margin-top: -40px
}
#settings-map-screen .upgrade-details2 .title1{
  background: white;
  width: fit-content;
}

#settings-map-screen .formula-container {
  display: flex;
  align-items: center;
}

#settings-map-screen .formula-container p {
  margin-right: 10px;
}

#settings-map-screen .addConditionButton{
  margin: 0px 85px 0px auto;
  width: 200px;
}

#settings-map-screen .upgrade-details .title2{
  display: flex;
}

#settings-map-screen .upgrade-details .title2 .line{
  border-top: dashed 1px  #c7c7c7;
  background: none;
  margin-left: -10px;
  margin-top: 30px;
  margin-right: 0px;
  width: 10px;
}

#settings-map-screen .upgrade-details .title3{
  display: flex;
}

#settings-map-screen .upgrade-details .title3 .line{
  border-top: dashed 1px  #c7c7c7;
  background: none;
  margin-left: -10px;
  margin-top: 30px;
  margin-right: 0px;
  width: 10px;
}

#settings-map-screen .upgrade-condition {
  display: flex;
}

#settings-map-screen .upgrade-condition .comparison{
  display: flex;
  margin: 0px 100px;
}

#settings-map-screen .upgrade-condition .input{
  margin-top: -10px;
}

#settings-map-screen .upgrade-condition .select .MuiFormControl-root {
  height: fit-content;
}

#settings-map-screen .upgrade-condition .buttonRow {
  margin-left: auto;
  display: flex;
  margin-top: -10px;
}

#settings-map-screen .upgrade-condition .buttonRow .deleteIcon{
  font-size: 24;
  margin-right: 25px
}

#settings-map-screen .container {
  display: flex;
  align-items: center; /* Center vertically */
}

#settings-map-screen .toggle-input-button:hover {
  cursor: pointer;
}

#settings-map-screen .settings-change,
#settings-map-screen .settings-change-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-width: 600px;
}

#settings-map-screen .arrow {
  margin: 0 5px; /* Adjust spacing between text and icon */
}

#settings-map-screen .subtext {
  font-size: smaller;
}
