@import 'src/style/variable/variables.module';

.data-table-loading {
  opacity: 50%;
  filter: blur(2px);
}
.data-table-row-hover:hover {
   border: 2px solid #009dc1
}
.data-table-row-hover-selected {
  border: 2px solid #026278
}
.data-table-banner {
  background: white;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  width: 500px;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  bottom: 10px;
  position: fixed;
  left: 50%;
  padding: 5px;
  z-index: 1000;
}
.data-table-inner {
  display: flex;
  width: 350px;
  margin: auto;
  justify-content: space-around;
  display: flex;
}
.data-table-pipe {
  font-weight: normal;
}

.clickable-text {
  color: #007bff;
  cursor: pointer;
}

.disabled-text {
  color: $grey-light;
  cursor: default;
}

.clickable-text:hover {
  text-decoration: none;
}

.data-table-placeholder {
  padding: 24px;
  text-align: center;
}

.MuiTable-root.no-pagination tbody tr:last-child td {
  border-bottom: 0px;
}


.table-filter .MuiTextField-root {
  float: right;
  margin-top: -50px;
  margin-bottom: 15px;
}

body .search-field .MuiFormLabel-root {
  font-size: 2rem
}

.table-filter .MuiFormLabel-root {
  padding-right: 6px;
  background: #fff;
}

body .table-filter .MuiTextField-root {
  margin-top: -45px;
  margin-bottom: 15px;
}

@media (max-width: 800px) {
  body .table-filter .MuiTextField-root {
    margin: 0 auto 15px auto;
    float: inherit;
  }
}
