@import 'src/style/variable/variables.module';

.replace-card-modal .MuiFormControl-root {
  margin: auto;
  display: flex;
}
.replace-card-modal {
  position: relative;
}
.file-upload-bottom {
  margin-bottom: 24px;
}

.replace-card-modal .spinner {
  position: absolute;
  top: 15px;
  right: 20px;
}
.file-upload {
  padding-left: 24px;
  padding-top: 24px;
}

@media (max-width: $mobile-width) {
  .file-upload {
    padding: 0px 0px 24px 0px;
  }
}
