@import 'src/style/variable/variables.module';


.circuits-list h3 {
  margin: 10px 0px;
}
@media (max-width: 800px) {
  .table-filter .MuiTextField-root {
    margin: 0 auto 15px auto;
    float: inherit;
  }
}
.table-filter .MuiTextField-root {
  max-width: 300px;
}

.forced-on-circuit-actions button {
  float: right;
  padding: 0 20px 0 0px;
}

.circuits-list tr.MuiTableRow-root:not(tr.MuiTableRow-head):hover {
  background-color: rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.line-through {
  text-decoration: line-through;
  color: $grey;
}

.circuits-list td.MuiTableCell-root {
  width: 25%;
  padding: 14px;
}
.alarm {
  color: $quaternary-flat
}

.alarmBadge > span {
  font-size: 12px;
}


.row-error {
  background-color: $error-light !important; /* Light red background for error */
  color: #333 !important; /* Black text color */
  font-weight: 600 !important; /* Use a medium or semi-bold font weight */
}

.row-error a {
  color: #1565c0 !important; /* Adjusted link color for better blending */
  transition: color 0.3s, font-weight 0.3s!important; /* Smooth transition for link color and font weight change */
}

.row-error a:hover {
  color: #0d47a1 !important; /* Darker link color on hover */
}

.row-error .MuiCheckbox-root {
  color: #333; /* Adjust the color for the unchecked checkbox */
}

.row-error .Mui-checked {
  color: #0d47a1; /* Adjust the color for the checked checkbox */
}

.row-error-warn {
  background-color: $warning !important; /* Light red background for error */
  color: #333 !important; /* Black text color */
  font-weight: 600 !important; /* Use a medium or semi-bold font weight */
}

.row-error-warn a {
  color: #1565c0 !important; /* Adjusted link color for better blending */
  transition: color 0.3s, font-weight 0.3s!important; /* Smooth transition for link color and font weight change */
}

.row-error-warn a:hover {
  color: #0d47a1 !important; /* Darker link color on hover */
}

.row-error-warn .MuiCheckbox-root {
  color: #333; /* Adjust the color for the unchecked checkbox */
}

.row-error-warn .Mui-checked {
  color: #0d47a1; /* Adjust the color for the checked checkbox */
}

.clear-button {
  padding-top: 2px;
  padding-bottom: 5px;
  right: 0;
  position: absolute;
  margin-right: 5px;
}
