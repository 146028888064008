@import 'src/style/variable/variables.module';

.editable-cell .MuiAutocomplete-root,
.editable-cell .MuiInputBase-root {
  float: left;
}

.editable-cell .MuiAutocomplete-root:not(.MuiInputBase-multiline),
.editable-cell .MuiInputBase-root:not(.MuiInputBase-multiline){
  padding: 4px 4px;
  height: 30px;
  cursor: pointer;
}

.editable-cell .button {
  float: right;
  margin: 0;
  padding: 0 5px;
}

.editable-cell .button:enabled:hover {
  box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.0);
}

.editable-cell .button:enabled:hover svg {
  fill: $secondary-dark;
}

.editable-cell .MuiInputBase-multiline {
  padding: 6px 4px;
  cursor: pointer;
}


.editable-cell .MuiInputBase-root.MuiOutlinedInput-root {
  margin: 0;
}

.editable-cell .MuiInputBase-root.MuiOutlinedInput-root input {
  padding: 0 10px;
}

.editable-cell .MuiSelect-root.MuiSelect-outlined {
  padding: 0 24px 0 4px;
  line-height: 18px;
}
.editable-cell .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined {
  top: 7px;
}

.editable-cell .MuiAutocomplete-root.MuiOutlinedInput-marginDense input.MuiAutocomplete-input,
.editable-cell .MuiInputBase-root.MuiOutlinedInput-marginDense input.MuiInputBase-input {
  margin: 0;
  padding: 0 4px;
  margin-left: -3px;
  font-size: 10pt;
  min-width: 100px;
}

.editable-cell .MuiInputBase-root:active,
.editable-cell .MuiAutocomplete-root:active
.editable-cell .MuiInputBase-root:focus,
.editable-cell .MuiAutocomplete-root:focus {
  outline: 0;
  border: 0;
}

.editable-cell-label {
  word-break: break-all;
  display: table-cell;
  min-width: 100px;
  height: 31px;
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
  padding: 0;
}
.editable-readonly {
  cursor: default;
}

.editable-cell-label p{
  word-wrap: break-word;
  display: inline;
}

.editable-cell .MuiFormControl-marginDense {
  margin: 0;
}

.editable-cell-empty {
  color: $grey-light;
  font-style: italic;
}

.editable-cell fieldset {
  border-color: $grey-light !important;
  border-width: 1px !important;
}

.editable-cell .check-button-save {
  z-index: 1000;
}

.editable-cell-label p {
  word-break: break-word;
}
